<template>
  <b-card>

    <h4 v-if="currentObject.event_name" class="mb-2">
      Event Booth <a :href="'/events/' + currentObject.event_id + '/view'">({{ currentObject.event_name }})</a>
    </h4>
    
    <div class="row">
      <div class="col-md-6">
        <div class="float-left">
          <b-button size="md" variant="primary" @click="add()" v-if="$can('add', 'event_booths')">
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            /><span>Add</span>
          </b-button>
        </div>

        <div class="float-left ml-1">
          <b-button size="md" variant="secondary" @click="downloadExcel()" v-if="$can('view', 'event_booths')">
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            /><span>Export Excel</span>
          </b-button>
        </div>
      </div>
      <div class="col-md-6">
        <!-- search input -->
        <div class="custom-search float-right">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                name="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
      </div>
    </div>

    <!-- table -->
    <div v-if="!rows || isLoadingTable || !currentObject.event_name" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
    </div>
    <div class="event-booth">
      <vue-good-table
        v-if="rows && !isLoadingTable"
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Row number -->
          <span
            v-if="props.column.field === 'row_number'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ (props.row.originalIndex + 1) }}</span>
          </span>

          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
          </span>

          <span
            v-if="props.column.field === 'url_image'"
            class="text-nowrap">
            <a :href="props.row.url" target="_blank">
              <qrcode-vue :value="props.row.url"></qrcode-vue>
            </a>
          </span>

          <span
          v-if="props.column.field === 'feedback_link'"
        class="text-nowrap"
        >
          <a :href="'' + props.row.feedback_url + ''" target="_blank" v-if="props.row.questions.length > 0">{{ props.row.feedback_url }}</a>
          <div v-if="props.row.questions.length == 0">-</div>
        </span>

          <!-- Column: Visitors -->
          <span
            v-if="props.column.field === 'visitors'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ (props.row.visits.length > 0) ? (props.row.visits.length) : '-' }}</span>
          </span>


          <!-- Column: Created by -->
          <span
            v-if="props.column.field === 'updated_by_user'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ (props.row.updated_by) ? props.row.updated_by.name : props.row.created_by.name }}</span>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="detailModal(props.row.id)" v-if="$can('view', 'event_booths')">
                  <feather-icon
                    icon="UserIcon"
                    class="mr-50"
                  />
                  <span>Visitors</span>
                </b-dropdown-item>
                <b-dropdown-item @click="viewFeedbackForms(props.row.id)" v-if="$can('view', 'questions')">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-50"
                />
                <span>Feedback Form</span>
              </b-dropdown-item>
                <b-dropdown-item @click="editModal(props.row.id)"  v-if="$can('edit', 'event_scans')">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="removeModal(props.row.id)" v-if="$can('delete', 'event_booths')">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>

    <div class="mt-2">
      <div style="float: left">
        <b-button size="md" variant="outline-primary" @click="viewAllEvents()">
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          /><span>Back</span>
        </b-button>
      </div>
    </div>

  <!-- Modals -->
    <b-modal
      id="modal-form"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showFormModal"
    >
      <template #modal-header="{}">
        <h5>{{ (isEdit) ? "Edit": "Add" }} {{ pageName }}</h5>
      </template>

      <div v-if="isLoadingForm" class="text-center">
          <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-form v-if="!isLoadingForm" @submit.prevent="save()">
        <b-form-group>
          <label for="user">Name:</label>
          <b-form-input
            v-model="currentObject.name"
            placeholder="Booth name"
            name="text"
            class="d-inline-block"
          />
        </b-form-group>
      </b-form>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="primary" @click="save()" :disabled="!isValid() || isLoadingForm">
          {{ (!isLoadingForm) ? "Save" : "Loading..." }}
        </b-button>
      </template>


    </b-modal>

    <b-modal
      id="modal-view"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showViewModal"
      size="xl"
    >
      <template #modal-header="{}">
        <h5>{{ pageName }} Visitors ({{currentObject.name}})</h5>
      </template>

      <event-booth-detail-table :data="currentObject" />

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Close
        </b-button>
      </template>

    </b-modal>

  <!-- Modals -->
    <b-modal
      id="modal-delete"
      cancel-variant="outline-secondary"
      cancel-title="Close"
      centered
      v-model="showDeleteModal"
    >
      <template #modal-header="{}">
        <h5>Delete {{ pageName }}</h5>
      </template>

      <p>
        Are you sure you want to delete this item?
      </p>

      <template #modal-footer="{ cancel }">
        <b-button size="md" variant="outline-secondary" @click="cancel()">
          Cancel
        </b-button>
        <b-button size="md" variant="danger" @click="remove()" :disabled="isLoadingDelete">
          {{ (isLoadingDelete) ? "Loading..." : "Delete" }}
        </b-button>
      </template>


    </b-modal>
  </b-card>

</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BForm, BButton, BSpinner, BRow, BCol
} from 'bootstrap-vue'
import router from '@/router'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EventBoothDetailTable from './EventBoothDetailTable.vue'
import moment from 'moment'
import * as XLSX from 'xlsx'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BRow,
    BCol,
    EventBoothDetailTable,
    XLSX,
    QrcodeVue
  },
  data() {
    return {
      isLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
            label: 'No',
            field: 'row_number'
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Visitors',
          field: 'visitors',
        },
        {
          label: 'URL',
          field: 'url_image',
        },
        {
          label: 'Feedback Form Link',
          field: 'feedback_link',
        },
        {
          label: 'Last Updated By',
          field: 'updated_by_user',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',

      isLoadingTable: false,
      isLoadingForm: false,
      isLoadingDelete: false,
      showDeleteModal: false,
      showViewModal: false,
      showFormModal: false,
      pageName: "Event Booth",

      currentObject: {
        id: '',
        name: '',
        event_id: -1,
        event_name: null,
        visits: [],
      },

      isEdit: false,
    }
  },
  computed: {
  },
  methods: {
    viewFeedbackForms(id) {
      this.$router.push('/event_booths/' + id + '/questions')
    },
    formatDateReverse(value) {
      return moment(String(value), 'YYYY-MM-DD H:mm:s').format('DD-MM-YYYY HH:mm')
    },
    editModal(id) {
      this.isLoadingForm = true

      this.isEdit = true
      this.showFormModal = true

      this.$http.get('/admin/v1/event_booths/' + id)
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.currentObject.id = id
            this.currentObject.name = currentData.name
          }

          this.isLoadingForm = false
      })
    },
    downloadExcel() {
      this.isLoading = true
      
      let excelRows = []

      this.rows.map(row => row.visits.map(visit => (excelRows.push({
        event_name: (row.event) ? row.event.name : '-',
        booth_name: (row.name) ? row.name : '-',
        first_name: (visit.user) ? visit.user.name : '-',
        last_name: (visit.user) ? visit.user.last_name : '-',
        salutation: (visit.user) ? visit.user.salutation : '-',
        email: (visit.user) ? visit.user.email : '-',
        phone: (visit.user && visit.user.profile) ? visit.user.profile.phone : '-',
        company: (visit.user && visit.user.profile) ? visit.user.profile.company : '-',
        home_address: (visit.user && visit.user.profile) ? visit.user.profile.home_address : '-',
        company: (visit.user && visit.user.profile) ? visit.user.profile.company : '-',
        company_address: (visit.user && visit.user.profile) ? visit.user.profile.company_address : '-',
        company_phone: (visit.user && visit.user.profile) ? visit.user.profile.company_phone : '-',
        // personal_email: (visit.user && visit.user.profile) ? visit.user.profile.personal_email : '-',
        company_email: (visit.user && visit.user.profile) ? visit.user.profile.company_email : '-',
        brand_name: (visit.user && visit.user.profile) ? visit.user.profile.brand_name : '-',
        company_size: (visit.user && visit.user.profile) ? visit.user.profile.company_size : '-',
        industry: (visit.user && visit.user.profile) ? visit.user.profile.industry : '-',
        position: (visit.user && visit.user.profile) ? visit.user.profile.position : '-',
        job_level: (visit.user && visit.user.profile) ? visit.user.profile.job_level : '-',
        company_city: (visit.user && visit.user.profile && visit.user.profile.company_city) ? visit.user.profile.company_city.name : '-',
        company_state: (visit.user && visit.user.profile  && visit.user.profile.company_city && visit.user.profile.company_city.state) ? visit.user.profile.company_city.state.name : '-',
        company_state: (visit.user && visit.user.profile  && visit.user.profile.company_city && visit.user.profile.company_city.state && visit.user.profile.company_city.state.country) ? visit.user.profile.company_city.state.country.name : '-',
        scanned_at: (visit.created_at) ? this.formatDateReverse(visit.created_at) : '-',
        scanned_by: (visit.created_by) ? row.created_by.name : '-',
      }))))

      let excelHeaders = []

      excelRows.forEach((row, index) => {
        for(const [objIndex, [key, value]] of Object.entries(Object.entries(row))) {

          if(index == 0) {
            const words = key.split("_");

            let colName = []

            words.forEach((word) => {
              colName.push(word[0].toUpperCase() + word.substring(1))
            })

            excelHeaders.push(colName.join(" "))
          }

          if(value == '' || value === null) {
            excelRows[index][key] = '-'
          }
        }
      })

      let overviewExcelRows = []
      let overviewExcelHeaders = []

      this.rows.forEach((row, index) => {
        overviewExcelRows.push({
          name: row.name,
          visitors: (row.visits.length > 0) ? row.visits.length : '-',
          created_at: (row.created_at) ? this.formatDateReverse(row.created_at) : '-',
          // last_updated_by: (row.created_by) ? this.formatDateReverse(row.created_by.name) : '-'
        })
      })

      overviewExcelRows.forEach((row, index) => {
        for(const [objIndex, [key, value]] of Object.entries(Object.entries(row))) {

          if(index == 0) {
            const words = key.split("_");

            let colName = []

            words.forEach((word) => {
              colName.push(word[0].toUpperCase() + word.substring(1))
            })

            overviewExcelHeaders.push(colName.join(" "))
          }

          if(value == '' || value === null) {
            overviewExcelRows[index][key] = '-'
          }
        }
      })

      const overviewSheet = XLSX.utils.json_to_sheet(overviewExcelRows)
      const worksheet = XLSX.utils.json_to_sheet(excelRows)

      const workbook = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(workbook, overviewSheet, "Overview")
      XLSX.utils.book_append_sheet(workbook, worksheet, "All Data")

      XLSX.utils.sheet_add_aoa(overviewSheet, [overviewExcelHeaders], { origin: "A1" });
      XLSX.utils.sheet_add_aoa(worksheet, [excelHeaders], { origin: "A1" });

      XLSX.writeFile(workbook, this.pageName + "_" + Date.now() + ".xlsx", { compression: true })

      this.isLoading = false
    },
    viewAllEvents() {
      this.$router.push('/events')
    },
    save() {
      this.isLoadingForm = true

      if(!this.isEdit) {
        this.$http.post('/admin/v1/events/' + this.currentObject.event_id + '/booths', {
          name: this.currentObject.name,
        })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showFormModal = false

          this.isLoadingForm = false

          this.fetchData()
        })
      }
      else {
        this.$http.put('/admin/v1/event_booths/' + this.currentObject.id, {
          name: this.currentObject.name,
        })
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }

          this.showFormModal = false

          this.isLoadingForm = false

          this.fetchData()

        })
      }
    },
    add() {
      this.showFormModal = true

      this.currentObject.event_id = router.currentRoute.params.id
      this.currentObject.name = ''
    },
    detailModal(id) {
      this.fetchData().then(res => {
        const data = this.rows.filter(obj => {
          return obj.id == id
        })

        let currentData = (data.length > 0) ? data[0] : null

        if(currentData !== null) {
          this.currentObject.name = currentData.name

          this.currentObject.visits = []

          currentData.visits.forEach((item) => {
            this.currentObject.visits.push(item)  
          })
        }

        this.showViewModal = true
      })

    },
    removeModal(id) {      
      this.showDeleteModal = true

      this.currentObject.id = id
      this.currentObject.event_id = router.currentRoute.params.id

    },
    remove() {
      this.isLoadingDelete = true

      this.$http.delete('/admin/v1/event_booths/' + this.currentObject.id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }
          
          this.showDeleteModal = false

          this.isLoadingDelete = false

          this.fetchData()
      })
    },
    fetchEvent() {
      const id = router.currentRoute.params.id

      this.$http.get('/admin/v1/events/' + id)
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data

            this.currentObject.event_name =  currentData.name
          }
     })
    },
    fetchData() {
      this.isLoadingTable = true

      const id = router.currentRoute.params.id

      this.currentObject.event_id = id

      return this.$http.get('/admin/v1/events/' + this.currentObject.event_id + '/booths')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
          
            this.rows = currentData
          }

          this.isLoadingTable = false

     })
    },
    isValid() {
      return this.currentObject.name !== null && this.currentObject.name != ''
    },
  },
  created() {
    this.fetchData()
    this.fetchEvent()
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.event-booth .vgt-responsive {
  min-height: 350px;
}
</style>
